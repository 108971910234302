.hide-prices & {
    .price-wrapper,                         /* mostly all the listings + campaign pages */
    .price-freight,
    .price.number,
    .product__prices,                       /* search */
    .priceFreight-completeGrid {            /* product page */
        visibility: hidden !important;
    }
}

.hide-prices &.no-config-bundle, .hide-prices &.config-bundle {
    .price {
        visibility: hidden !important;
    }
}

.hide-prices-toggle {
    text-align: center;
}
