#block-content, .popup {
    .button-primary {
        color: $button-primary-color;

        &:hover, &:active, &:focus {
            color: $button-primary-color;
        }
    }

    #checkout {
        .button-primary {
            color: $button-primary-color;

            &:hover, &:active, &:focus {
                color: $button-primary-color;
            }
        }
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    .quantity-picker ~ button.added-to-cart {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        & > .text {
            margin-left: 20px;
        }
    }
}
