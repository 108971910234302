//Colors
$main-color: #ea6a20;
$secondary-color: #002942;

//Links
$link-hover-color: $secondary-color;
$link-active-color: $link-hover-color;

//Buttons
$button-primary-color: $color-default-white;
$button-primary-disabled-color: $button-primary-color;
$button-primary-background-hover: #f56722;
$button-primary-background-active: #db631e;
$button-primary-border-bottom-color: #c3581b;
$button-primary-disabled-background: rgba($main-color, .7);